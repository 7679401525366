// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.packing-list-container {
    display: flex;
    width: 100%;
    flex-grow: 1;
  }
  
  .packing-list-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .packing-list-table th,
  .packing-list-table td {
    padding: 8px;
    text-align: left;
  }
  
  .packing-list-table thead {
  }
  
  .packing-list-table input {
    width: 100%;
    padding: 4px;
    border: 1px solid #ccc;
    outline: none;
    border-radius: 4px;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/PackingListTable.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,WAAW;IACX,YAAY;EACd;;EAEA;IACE,WAAW;IACX,yBAAyB;EAC3B;;EAEA;;IAEE,YAAY;IACZ,gBAAgB;EAClB;;EAEA;EACA;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,sBAAsB;IACtB,aAAa;IACb,kBAAkB;EACpB","sourcesContent":[".packing-list-container {\n    display: flex;\n    width: 100%;\n    flex-grow: 1;\n  }\n  \n  .packing-list-table {\n    width: 100%;\n    border-collapse: collapse;\n  }\n  \n  .packing-list-table th,\n  .packing-list-table td {\n    padding: 8px;\n    text-align: left;\n  }\n  \n  .packing-list-table thead {\n  }\n  \n  .packing-list-table input {\n    width: 100%;\n    padding: 4px;\n    border: 1px solid #ccc;\n    outline: none;\n    border-radius: 4px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
