import { isNaN } from 'lodash';
import { get } from './utility';

export const INSPECTION_STATES = {
  new: 'new',
  inProgress: 'inProgress',
  requiresAttention: 'requiresAttention',
  completed: 'completed',
};

export const INSPECTION_ROLES = {
  admin: 'admin',
  onsite: 'onsite',
};

export const INSPECTION_IMAGE_TYPES = {
  productImages: 'productImages',
  shipmentImages: 'shipmentImages',
};

export const INSPECTION_PARTICIPANT_TYPES = {
  applicant: 'applicant',
  shipper: 'shipper',
  consignee: 'consignee',
};

export const INSPECTION_IMAGE_QUALITY_LEVELS = {
  max: 'max',
  high: 'high',
  medium: 'medium',
  low: 'low',
};

export const INSPECTION_IMAGE_DOWNLOAD_ZIP_TYPES = {
  all: 'all',
  selected: 'selected',
};

export const INSPECTION_DECLARED_GRADES = {
  _1: '#1',
  _2: '#2',
  canada1: 'Canada #1',
  canada2: 'Canada #2',
  canadaCommercial: 'Canada Commercial',
  canadaExtraFancy: 'Canada Extra Fancy',
  canadaFancy: 'Canada Fancy',
  cat1: 'CAT1',
  cat2: 'CAT2',
  choice: 'Choice',
  choice2: 'Choice - #2',
  extraFancy: 'Extra Fancy',
  fancy: 'Fancy',
  fancy1: 'Fancy - #1',
  us1: 'US #1',
  us2: 'US #2',
  usChoce: 'US Choice',
  usCommercial: 'US Commercial',
  usExtraFancy: 'US Extra Fancy',
  usFancy: 'US Fancy',
  waExtraFancy: 'WA Extra Fancy',
  waFancy: 'WA Fancy',
};

export const INSPECTION_INSPECTED_GRADES = {
  us1: 'US #1',
  us2: 'US #2',
  canada1: 'Canada #1',
  canada2: 'Canada #2',
  no1: '#1 ',
  no2: '#2',
  choice: 'Choice',
  extraFancy: 'Extra Fancy',
  fancy: 'Fancy',
};

export const INSPECTION_PACKAGING_TYPES = {
  bags: 'Bags',
  bins: 'Bins',
  boxes: 'Boxes',
  cartons: 'Cartons',
  flats: 'Flats',
  lugs: 'Lugs',
  meshBags: 'Mesh Bags',
  pallets: 'Pallets',
  plasticBags: 'Plastic Bags',
  plasticBoxes: 'Plastic Boxes',
  plasticCrates: 'Plastic Crates',
  styrofoamBoxes: 'Styrofoam Boxes',
  styrofoamLugs: 'Styrofoam Lugs',
  wireBound: 'Wire-Bound Crates',
  wood: 'Wood Crates',
};

export const INSPECTION_SIZING_METHODS = {
  count: 'Count',
  size: 'Size',
};

export const INSPECTION_WEIGHT_UNITS = {
  lbs: 'lbs',
  kg: 'kg',
};

export const INSPECTION_TABS = {
  participants: 'participants',
  shipment: 'shipment',
  onsite: 'onsite',
  analysis: 'analysis',
  report: 'report',
};

export const INSPECTION_RETRIEVE_OPTIONS = {
  active: 'active',
  last7days: 'last 7 days',
  last30days: 'last 30 days',
  completed: 'completed',
  all: 'all',
};

export const INSPECTION_CREATE_FORM_MODES = {
  new: 'new',
  edit: 'edit',
  duplicate: 'duplicate',
};

export const INSPECTION_PRODUCT_FORM_MODES = {
  new: 'new',
  edit: 'edit',
  duplicate: 'duplicate',
};

export const INSPECTION_SAMPLE_FORM_MODES = {
  new: 'new',
  edit: 'edit',
  duplicate: 'duplicate',
};

export const getInspectionsStatusColor = (inpectionStatus) => {
  let color = 'inherit';
  let title = '';

  if (inpectionStatus === INSPECTION_STATES.inProgress) {
    color = 'blue';
    title = 'Inspection in progress';
  } else if (inpectionStatus === INSPECTION_STATES.new) {
    color = 'green';
    title = 'Inspection requested';
  } else if (inpectionStatus === INSPECTION_STATES.requiresAttention) {
    color = 'red';
    title = 'Inspection requires attention';
  } else if (inpectionStatus === INSPECTION_STATES.completed) {
    title = 'Inspection completed';
  }
  return { title, color };
};

export const getInsectionProductFromSample = (inspection, sample) => {
  const products = get(inspection, 'shipment.products', []);

  for (const product of products) {
    if (product._id.toString() === get(sample, 'productId')) {
      return product;
    }
  }
  return null;
};

export const renderProductDescription = (product) => {
  const title = [];
  const subtitle = [];

  const description = get(product, 'description', false);
  const marking = get(product, 'marking', false);
  const grade = get(product, 'grade', false);
  const quantity = get(product, 'quantity', false);
  const packingType = get(product, 'packingType', false);
  const sizingMethod = get(product, 'sizingMethod', false);
  const size = get(product, 'size', false);
  const weight = get(product, 'weight', false);
  const weightUnit = get(product, 'weightUnit', false);
  const referenceType = get(product, 'referenceType', false);
  const referenceNumber = get(product, 'referenceNumber', false);

  if (description) {
    title.push(description);
  }
  if (marking) {
    title.push(marking);
  }
  if (quantity) {
    subtitle.push(quantity);
  }
  if (packingType) {
    subtitle.push(packingType);
  }
  if (grade) {
    subtitle.push(grade);
  }
  if (sizingMethod) {
    subtitle.push(sizingMethod);
  }
  if (size) {
    subtitle.push(size);
  }
  if (weight) {
    subtitle.push(weight);
  }
  if (weightUnit) {
    subtitle.push(weightUnit);
  }
  if (referenceType) {
    subtitle.push(referenceType);
  }
  if (referenceNumber) {
    subtitle.push(referenceNumber);
  }

  return { title: title.join(' '), subtitle: subtitle.join(' '), all: (title.concat(subtitle)).join(' ') };
};

export const populateProductsSelection = (products) => {
  const options = [];
  products.forEach((product) => {
    const productLine = [];
    // if (product?.quantity) productLine.push(`${product.quantity}`);
    // if (product?.shippingUnit) productLine.push(`${product.shippingUnit} of`);
    if (product?.description) productLine.push(`${product.description}`);
    options.push({
      value: product._id.toString(),
      label: renderProductDescription(product).all,
    });
  });
  return options;
};

// Helper function to check if a value is a numeric string
const isNumericString = (value) => (
  !isNaN(value) && !isNaN(parseFloat(value))
);

export const renderDefectRanges = (defect) => {
  const result = [];

  const average = get(defect, 'average', false);
  const rangeMin = get(defect, 'rangeMin', false);
  const rangeMax = get(defect, 'rangeMax', false);

  if (average) {
    result.push(`Average: ${average}${isNumericString(average) ? '%' : ''}`);
  }
  if (rangeMin) {
    result.push(`Min: ${rangeMin}${isNumericString(rangeMin) ? '%' : ''}`);
  }
  if (rangeMax) {
    result.push(`Max: ${rangeMax}${isNumericString(rangeMax) ? '%' : ''}`);
  }

  return result.join(', ');
};

export const getDefectFromDescription = (organization, defectDescription) => {
  const defects = get(organization, 'inspectionSettings.defectDefinitions', []);
  return defects.find((defect) => (defect.description.toLowerCase() === defectDescription.toLowerCase()));
};

export const retrieveProductImagesAvailableForAssociation = (productImages) => (
  productImages.filter((image) => !image.sampleId)
);

export const retrieveProductImagesCurrentlyUsedForAssociation = (productImages, sampleId, defectId) => {
  // If both sampleId and defectId are provided, filter by both
  if (sampleId && defectId) {
    return productImages.filter((image) => image.sampleId === sampleId && image.defectId === defectId);
  } if (defectId) {
    // If only defectId is provided, filter by defectId
    return productImages.filter((image) => image.defectId === defectId);
  } if (sampleId) {
    // If only sampleId is provided, return images with a matching sampleId and an undefined or null defectId
    return productImages.filter((image) => image.sampleId === sampleId && (image.defectId === undefined || image.defectId === null));
  }
  // If neither is provided, return an empty array
  return [];
};
