import './App.css';
import { useMediaQuery } from 'react-responsive';
import {
  Route, Routes, useLocation, useNavigate,
} from 'react-router-dom';

import { connect } from 'react-redux';
import { React, useEffect, useState } from 'react';
import axios from 'axios';
import {
  credsStatusGet,
  csrfGetRequest,
  getImagesLeftToSyncCount,
  organizationGet,
  signOut, userGetMe,
} from './actions';
import Backdrop from './components/Backdrop';
import BackgroundVideo from './components/BackgroudVideo';
import BookingPage from './containers/BookingPage';
import Footer from './components/Footer';
import Header from './components/Header';
import { get, permissionCheck } from './utility/utility';
import Navigation from './components/Navigation';
import OrganizationPage from './containers/OrganizationPage';
import PasswordForgot from './containers/PasswordForgot';
import PasswordReset from './containers/PasswordReset';
import ReportingPage from './containers/ReportingPage';
import SignIn from './containers/SignIn';
import StatusBanner from './components/StatusBanner';
import SignupInvite from './containers/SignupInvite';
import InspectionPage from './containers/InspectionPage';
import { MEDIA_MOBILE_MAX_WIDTH } from './utility/AppParameters';
import {
  API_BASE_URL, IMAGE_RESIZE_MAX_SIDE_LENGTH, IMAGE_RESIZE_QUALITY, IMAGE_SYNC_BATCH_SIZE,
} from './config';
import {
  serviceWorkerCheckSupport,
  initServiceWorker,
  setDispatchFunction,
} from './utility/serviceWorkerUtils';
import QuickbooksPage from './containers/QuickbooksPage';
import TermsPage from './containers/TermsPage';

// Usage: Inject axios functions or any other compatible functions
import { createApiClient } from './utility/apiClient';
import { branding } from './utility/brandUtils';
import createCsrf from './utility/csrfUtil';
import CodeVerification from './containers/CodeVerification';
import { AUTH_REQUESTS } from './reducers/authReducer';
import { log } from './utility/logging';

const csrf = createCsrf();

export const apiClient = createApiClient({
  csrfTokenGet: csrf.get,
  apiBaseUrl: API_BASE_URL,
  httpGet: axios.get,
  httpPatch: axios.patch,
  httpPost: axios.post,
  httpPut: axios.put,
  httpDelete: axios.delete,
});

function checkIndexedDBSupport() {
  if (('indexedDB' in window)) {
    return true;
  }
  return false;
}

function App({
  me,
  isSignedIn,
  mfaCodeIsRequired,
  mfaEmail,
  credsAreValid,
  credsError,
  credsStatusGetIsLoading,
  csrfToken,
  csrfGetIsLoading,
  isSigingOut,
  dispatchCsrfGet,
  dispatchUserGetMe,
  dispatchSignOut,
  dispatchCredsStatusGet,
  dispatchOrganizationsGet,
  dispatchGetImagesLeftToSyncCount,
  organization,
  notificationSeverity,
  notificationMessage,
  notificationIsShown,
}) {
  const isDesktopOrLaptop = useMediaQuery({ minDeviceWidth: MEDIA_MOBILE_MAX_WIDTH });
  const [menuState, setMenuState] = useState(false);
  const [userIsAuthed, setUserIsAuthed] = useState(false);
  const [navigationOptions, setNavigationOptions] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const pathsToSignOutPrefixes = ['/signin', '/forgot-password', '/reset-password', '/signup-invite'];
    const shouldSignOut = pathsToSignOutPrefixes.some((prefix) => location.pathname.startsWith(prefix));

    if (shouldSignOut) {
      dispatchSignOut();
    }
  }, [location.pathname]); // Rerun effect when location changes

  const [isCheckingAuth, setIsCheckingAuth] = useState(false); // Local flag to prevent re-entry
  useEffect(() => {
    const checkAuthStatus = async () => {
      if (isCheckingAuth) return; // Prevent re-entry if we're already checking
      setIsCheckingAuth(true);

      try {
        if (isSigingOut) {
          // Signing out has been triggered
          setUserIsAuthed(false);
        } else if (mfaCodeIsRequired) {
          // Navigate to the code verification view
          navigate(`/verify?email=${encodeURIComponent(mfaEmail)}`);
        } else if (!credsError && !isSignedIn && !credsAreValid && !credsStatusGetIsLoading) {
          // Check if valid auth tokens are stored in cookies (first-time load)
          await dispatchCredsStatusGet();
        } else if (!isSignedIn && credsAreValid && !csrfToken && !csrfGetIsLoading) {
          // Fetch the CSRF token to establish an authenticated session
          await dispatchCsrfGet();
        } else if (isSignedIn && !credsAreValid && !csrfToken && !csrfGetIsLoading) {
          // Fetch CSRF token if the user manually signed in
          await dispatchCsrfGet();
        } else if (csrfToken && !me && !organization) {
          // Set CSRF token, mark user as authenticated, fetch user and organization data
          csrf.set({ csrfToken });
          setUserIsAuthed(true);
          await dispatchUserGetMe();
          await dispatchOrganizationsGet();
        }
      } catch (error) {
        log('Error in checkAuthStatus:', error);
      } finally {
        setIsCheckingAuth(false); // Reset flag when finished
      }
    };

    checkAuthStatus();
  }, [
    isSignedIn,
    mfaCodeIsRequired,
    mfaEmail,
    credsAreValid,
    credsError,
    credsStatusGetIsLoading,
    csrfToken,
    csrfGetIsLoading,
    isSigingOut,
    me,
    organization,
  ]);

  useEffect(() => {
    if (csrfToken
      && get(organization, 'inspectionSettings', false)) {
      // Check for browser capabilities
      if (!serviceWorkerCheckSupport() || !checkIndexedDBSupport()) {
        log('Browser does not support required storage and processsing features.');
        return;
      }

      const serviceWorkerConfig = {
        api: {
          baseUrl: API_BASE_URL,
          csrfToken,
          batchSize: IMAGE_SYNC_BATCH_SIZE,
          imageMaxSideLength: IMAGE_RESIZE_MAX_SIDE_LENGTH,
          imageQuality: IMAGE_RESIZE_QUALITY,
        },
        sync: {
          filePath: '/',
          fileName: 'syncSw.js',
          syncEventTag: 'syncInspectionImages',
        },
        callbacks: {
          dispatchGetImagesLeftToSyncCount,
        },
      };
      setDispatchFunction(dispatchGetImagesLeftToSyncCount);

      initServiceWorker(serviceWorkerConfig);
      
    }
  }, [csrfToken, organization]);

  const handleMenuClick = () => {
    if (menuState) {
      setMenuState(false);
    } else {
      setMenuState(true);
    }
  };

  useEffect(() => {
    const newNavigationOptions = [];
    if (permissionCheck(me, ['permissions.bookings.view'])) {
      newNavigationOptions.push('Bookings');
    }
    if (permissionCheck(me, ['permissions.reporting.view'])) {
      newNavigationOptions.push('Reporting');
    }
    if (permissionCheck(me, ['permissions.organization.view'])) {
      newNavigationOptions.push('Organization');
    }
    if (permissionCheck(me, ['permissions.inspections.view'])) {
      newNavigationOptions.push('Inspections');
    }

    // Update state with new navigation options
    setNavigationOptions(newNavigationOptions);
  }, [me]); // Ensure this runs when me or organization changes

  const displayDefaultPage = () => {
    if (permissionCheck(me, ['permissions.inspections.view'])) {
      return (<Route path="/*" element={<InspectionPage />} />);
    }

    return <Route path="/*" element={<BookingPage />} />;
  };

  const renderSignInPage = () => {
    // This error check is to prevent flash-displaying the login page, while the api requests are happening
    if (credsError) {
      // The user needs to sign in
      return (
        // Login Page
        <div className="app-public-view">
          <Routes>
            <Route exact path="/signin" element={<SignIn />} />
            <Route exact path="/forgot-password" element={<PasswordForgot />} />
            <Route path="/reset-password" element={<PasswordReset />} />
            <Route path="/verify" element={<CodeVerification />} />
            <Route path="/signup-invite" element={<SignupInvite />} />
            <Route exact path="/terms" element={<TermsPage />} />
            <Route path="/*" element={<SignIn />} />
          </Routes>
          {isDesktopOrLaptop && location.pathname !== '/terms' ? <BackgroundVideo videoSrc="ship.mp4" /> : null}
        </div>
      );
    }

    return <div className="app-preload-backdrop" />;
  };

  const renderDashboard = () => (
    <div className="dashboardView">
      <Navigation options={navigationOptions} isShown={menuState} signOut={dispatchSignOut} onClick={handleMenuClick} />
      <Backdrop onClick={handleMenuClick} isShown={menuState} />
      <Routes>
        <Route exact path="/bookings" element={<BookingPage />} />
        <Route exact path="/reporting" element={<ReportingPage />} />
        <Route exact path="/organization" element={<OrganizationPage />} />
        <Route exact path="/integration/quickbooks" element={<QuickbooksPage apiClient={apiClient} />} />
        <Route exact path="/terms" element={<TermsPage />} />

        {permissionCheck(me, ['permissions.inspections.view']) && <Route exact path="/inspections" element={<InspectionPage />} />}
        {displayDefaultPage(get(me, 'permissions', {}))}
      </Routes>
    </div>
  );

  return (
    <>
      <Header
        brandName={branding.name.toUpperCase()}
        showMenuButton={userIsAuthed}
        onMenuClick={handleMenuClick}
      />
      {
        notificationIsShown
          ? <StatusBanner statusSeverity={notificationSeverity} statusMessage={notificationMessage} />
          : <div />
      }
      <Footer />
      {(userIsAuthed) ? renderDashboard() : renderSignInPage()}
    </>
  );
}

const mapStateToProps = (state) => ({
  // SignInPost
  isSignedIn: get(state.auth, `${AUTH_REQUESTS.signInPost}.isSignedIn`, false),
  mfaCodeIsRequired: get(state.auth, `${AUTH_REQUESTS.signInPost}.mfaCodeIsRequired`, false),
  mfaEmail: get(state.auth, `${AUTH_REQUESTS.signInPost}.mfaEmail`, false),

  // credsGet
  credsAreValid: get(state.auth, `${AUTH_REQUESTS.credsStatusGet}.credsAreValid`, false),
  credsError: get(state.auth, `${AUTH_REQUESTS.credsStatusGet}.error`, false),
  credsStatusGetIsLoading: get(state.auth, `${AUTH_REQUESTS.credsStatusGet}.isLoading`, false),

  // csrfGet
  csrfToken: get(state.auth, `${AUTH_REQUESTS.csrfGet}.csrfToken`, false),
  csrfGetIsLoading: get(state.auth, `${AUTH_REQUESTS.csrfGet}.isLoading`, false),

  // signOutPost
  isSigingOut: get(state.auth, `${AUTH_REQUESTS.signOutPost}.isLoading`, false),

  // meGet
  me: get(state.users, 'me', false),

  // orgGet
  organization: get(state.organizations, 'organization', false),

  notificationIsShown: state.notifications.isShown,
  notificationSeverity: state.notifications.severity,
  notificationMessage: state.notifications.message,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchUserGetMe: () => dispatch(userGetMe()),
  dispatchCsrfGet: () => dispatch(csrfGetRequest()),
  dispatchCredsStatusGet: () => dispatch(credsStatusGet()),
  dispatchOrganizationsGet: () => dispatch(organizationGet()),
  dispatchSignOut: () => dispatch(signOut()),
  dispatchGetImagesLeftToSyncCount: (uploadedImages) => dispatch(getImagesLeftToSyncCount(uploadedImages)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
