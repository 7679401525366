import React, {
  useState, useEffect, forwardRef, useImperativeHandle,
} from 'react';
import _ from 'lodash';
import { get } from '../../../utility/utility';
import CoreFormDualAction from '../../core/form/CoreFormDualAction';
import InputField from '../../core/input/InputField';
import { validateForm } from '../../core/input/CoreInputUtils';

const fieldsToValidate = ['commodity'];
const OrganizationFormCommodity = forwardRef(({
  dispatchOrganizationsPut,
  organizationPutIsLoading,
}, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentCommodities, setCurrentCommodities] = useState([]);
  const [inputState, setInputState] = useState({});
  const [error, setError] = useState({});
  useImperativeHandle(ref, () => ({
    open: (organization, commodity) => {
      const commodities = get(organization, 'inspectionSettings.commodities');
      setCurrentCommodities(_.cloneDeep(commodities));
      if (commodity) {
        setInputState(_.cloneDeep(commodity));
      } else {
        setInputState({});
      }
      setIsOpen(true);
      setError({});
    },
    close: () => setIsOpen(false),
  }));

  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (!organizationPutIsLoading) {
      closeModal();
    }
  }, [organizationPutIsLoading]);

  if (!isOpen) return null;

  const onInputChange = (event) => {
    const newState = { ...inputState };
    newState[event.target.id] = event.target.value;
    setInputState(newState);
    validateForm({
      state: newState,
      fieldsToValidate,
      setError,
    });
  };

  const handleSave = () => {
    const validation = validateForm({
      state: inputState,
      fieldsToValidate,
      setError,
    });

    if (validation) {
      const commodityIndex = currentCommodities.findIndex(
        (element) => get(element, '_id').toString() === inputState._id,
      );

      if (commodityIndex !== -1) {
        // Update existing commodity
        dispatchOrganizationsPut({
          commoditiesUpdates: { commoditiesToUpdate: [inputState] },
        });
      } else {
        // Add new defect
        dispatchOrganizationsPut({
          commoditiesUpdates: { commoditiesToAdd: [inputState] },
        });
      }
    }
  };

  const renderInputField = (id, state, path = id) => (
    <InputField
      id={id}
      label={_.startCase(id)}
      placeholder={_.startCase(id)}
      value={get(state, path)}
      onChange={(event) => onInputChange(event, path)}
    />
  );

  return (
    <CoreFormDualAction
      title="Commodity"
      primaryButtonCaption="Save"
      primaryButtonCallback={() => handleSave()}
      primaryButtonCallbackStatus={organizationPutIsLoading}
      primaryButtonDisabled={Object.keys(error).length > 0 || organizationPutIsLoading}
      secondaryButtonCaption="Cancel"
      secondaryButtonCallback={closeModal}
    >
      {renderInputField('commodity', inputState)}
    </CoreFormDualAction>
  );
});

export default OrganizationFormCommodity;
