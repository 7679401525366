import React from 'react';
import './TitleCard.css';
import SortSelect from '../SortSelect';

function TitleCard({
  title,
  subtitle,
  sortOptions,
  handleTableHeaderClick,
  actions = [],
  setIsSelectMode,
  isSelectMode,
}) {
  const handleSelectChange = (event) => {
    handleTableHeaderClick({ title: event.target.value });
  };

  return (
    <div className="title-card">
      <div className="title-card-header">
        <div className="title-card-title">
          {title}
        </div>
        <div
          className="title-card-select"
          onClick={() => setIsSelectMode(!isSelectMode)}
        >
          {isSelectMode ? 'Cancel' : 'Select'}
        </div>
        <div className="title-card-actions-container">
          {sortOptions
            && <SortSelect placeholder="sort" options={sortOptions} onChange={handleSelectChange} />}
          {actions.map((action, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={index}>
              {action}
            </div>
          ))}
        </div>
      </div>
      {subtitle && (
        <div className="title-card-subtitle">
          {subtitle}
        </div>
      )}
    </div>
  );
}

export default TitleCard;
