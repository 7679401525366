import React, {
  useState, useEffect, forwardRef, useImperativeHandle,
} from 'react';
import { get } from '../../../utility/utility';
import CoreFormDualAction from '../../core/form/CoreFormDualAction';

const InspectionFormDelete = forwardRef(({
  dispatchInspectionsDelete,
  inspectionsDeleteIsLoading,
}, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentInspections, setCurrentInspections] = useState([]);

  useImperativeHandle(ref, () => ({
    open: (inspections) => {
      setCurrentInspections([...inspections]);
      setIsOpen(true);
    },
    close: () => setIsOpen(false),
  }));

  useEffect(() => {
    if (!inspectionsDeleteIsLoading) {
      setIsOpen(false);
    }
  }, [inspectionsDeleteIsLoading]);

  if (!isOpen) return null;

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <CoreFormDualAction
      title="Delete Inspection"
      primaryButtonCaption="Delete"
      primaryButtonCallback={() => dispatchInspectionsDelete({ inspections: currentInspections.map((inspection) => inspection._id) })}
      primaryButtonCallbackStatus={inspectionsDeleteIsLoading}
      secondaryButtonCaption="Cancel"
      secondaryButtonCallback={closeModal}
    >
      <div>
        <p>
          Confirm delete for:
        </p>
        {currentInspections.map((inspectionElement) => (
          <p key={inspectionElement._id} style={{ paddingLeft: '25px' }}>
            <span>- </span>
            {get(inspectionElement, 'summary.certificateId')}
          </p>
        ))}
      </div>
    </CoreFormDualAction>
  );
});

export default InspectionFormDelete;
