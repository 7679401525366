import React, {
  useState, useEffect, forwardRef, useImperativeHandle,
} from 'react';

import CoreFormDualAction from '../../core/form/CoreFormDualAction';

const OrganizationFormBatchCreateInvoice = forwardRef(({
  organizationBatchCreateInvoicesIsLoading,
  dispatchInspectionsCreateBatchInvoicePost,
}, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  useImperativeHandle(ref, () => ({
    open: () => {
      setIsOpen(true);
    },
    close: () => setIsOpen(false),
  }));

  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (!organizationBatchCreateInvoicesIsLoading) {
      closeModal();
    }
  }, [organizationBatchCreateInvoicesIsLoading]);

  if (!isOpen) return null;

  const handleCreateBatchInvoices = () => {
    dispatchInspectionsCreateBatchInvoicePost();
  };

  return (
    <CoreFormDualAction
      title="Batch Create Invoice (Quickbooks)"
      primaryButtonCaption="Batch Create"
      primaryButtonCallback={() => handleCreateBatchInvoices()}
      primaryButtonCallbackStatus={organizationBatchCreateInvoicesIsLoading}
      primaryButtonDisabled={organizationBatchCreateInvoicesIsLoading}
      secondaryButtonCaption="Cancel"
      secondaryButtonCallback={closeModal}
    >
      <p>This batch action processes completed inspections that have at least 1 generated report and no invoices. It then attempts to create the missing invoice.</p>
      <p>If there are some missing data points to generate any of the invoices, the batch action will gracefully skip it.</p>
      <p>It will not create invoice for customer names that do not already exist in QuickBooks. At least one manual invoice has to be created for each new customer to create them.</p>
      <p>It will only process a set amount of inspections in a batch, based on environment variable in the backend.</p>
    </CoreFormDualAction>
  );
});

export default OrganizationFormBatchCreateInvoice;
