import React, { useEffect, useRef } from 'react';
import './InspectionInvoiceCard.css';
import { get, getTimestampString } from '../../../utility/utility';
import CoreCard from '../../core/content/CoreCard';
import { INSPECTIONS_PENDING_REPORTS_FETCH_INTERVAL_MS } from '../../../config';

function InspectionInvoiceCard({
  invoice, primaryAction, secondaryActions, dispatchInspectionsGetSingle, isLoading, inspectionId,
}) {
  const intervalRef = useRef(null);

  useEffect(() => {
    if (isLoading) {
      intervalRef.current = setInterval(() => {
        dispatchInspectionsGetSingle({ inspectionId });
      }, INSPECTIONS_PENDING_REPORTS_FETCH_INTERVAL_MS);
    } else {
      clearInterval(intervalRef.current);
    }

    return () => {
      clearInterval(intervalRef.current);
    };
  }, [dispatchInspectionsGetSingle]);

  return (
    <CoreCard
      content={(
        <div className="inspection-invoice-card-info">
          <div style={{ paddingLeft: '10px', color: 'var(--color-grey900)', fontWeight: '500' }}>
            <span
              style={{
                cursor: (isLoading ? null : 'pointer'),
                textDecoration: 'underline',
              }}
              onClick={isLoading ? null : primaryAction}
            >
              {`${get(invoice, 'invoiceNumber')} (${get(invoice, 'invoiceDate')}) ${get(invoice, 'subtotal')}$`}
            </span>
            {' '}
          </div>
          <div style={{ paddingLeft: '10px', color: 'var(--color-grey700)' }}>
            {(get(invoice, 'customerName'))}
          </div>
          <div style={{ paddingLeft: '10px', color: 'var(--color-grey700)' }}>
            {(get(invoice, 'description'))}
          </div>
          {isLoading ? null : (
            <div style={{ paddingLeft: '10px', color: 'var(--color-grey700)' }}>
              {' '}
              {get(getTimestampString(invoice.createdAt), 'all', '')}

              {' '}
              {get(invoice, 'isSubmitted', false) && 'Submitted'}
            </div>
          )}
        </div>
      )}
      actions={secondaryActions}
      isLoading={isLoading}
    />
  );
}

export default InspectionInvoiceCard;
