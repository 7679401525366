import {
  ORGANIZATION_GET_REQUEST_START,
  ORGANIZATION_GET_REQUEST_SUCCESS,
  ORGANIZATION_GET_REQUEST_FAIL,
  ORGANIZATION_GET_INSPECTORS_REQUEST_START,
  ORGANIZATION_GET_INSPECTORS_REQUEST_SUCCESS,
  ORGANIZATION_GET_INSPECTORS_REQUEST_FAIL,
  ORGANIZATION_POST_BATCH_CREATE_INVOICES,
  ORGANIZATION_PUT,
} from '../actions';
import {
  createRequestStateUpdater, handleRequestState, REQUEST_STATE, updateElementSingle,
} from '../utility/reduxUtils';
import { updateObject } from '../utility/utility';

const initialState = {};

export const ORGANIZATION_REQUESTS = {
  orgGet: 'orgGet',
  orgPut: 'orgPut',
  orgGetInspectors: 'orgGetInspectors',
  orgBatchCreateInvoices: 'orgBatchCreateInvoices',
};

// eslint-disable-next-line default-param-last
const reducer = (state = initialState, action) => {
  switch (action.type) {
    // Organizations Get
    case ORGANIZATION_GET_REQUEST_START:
      return createRequestStateUpdater(state, action, ORGANIZATION_REQUESTS.orgGet);
    case ORGANIZATION_GET_REQUEST_SUCCESS:
      return updateObject(
        createRequestStateUpdater(state, action, ORGANIZATION_REQUESTS.orgGet),
        { organization: action.data },
      );
    case ORGANIZATION_GET_REQUEST_FAIL:
      return createRequestStateUpdater(state, action, ORGANIZATION_REQUESTS.orgGet);

    // Organizations Get Inspectors
    case ORGANIZATION_GET_INSPECTORS_REQUEST_START:
      return createRequestStateUpdater(state, action, ORGANIZATION_REQUESTS.orgGetInspectors);
    case ORGANIZATION_GET_INSPECTORS_REQUEST_SUCCESS:
      return updateObject(
        createRequestStateUpdater(state, action, ORGANIZATION_REQUESTS.orgGetInspectors),
        { inspectors: action.data },
      );
    case ORGANIZATION_GET_INSPECTORS_REQUEST_FAIL:
      return createRequestStateUpdater(state, action, ORGANIZATION_REQUESTS.orgGetInspectors);

    case `${ORGANIZATION_PUT}_${REQUEST_STATE.START}`:
    case `${ORGANIZATION_PUT}_${REQUEST_STATE.SUCCESS}`:
    case `${ORGANIZATION_PUT}_${REQUEST_STATE.FAIL}`:
      return handleRequestState({
        state,
        action,
        requestKey: ORGANIZATION_REQUESTS.orgPut,
        dataKey: 'organization',
        updateFunction: () => updateElementSingle({ current: state.organization, edited: action.data }),
      });

    case `${ORGANIZATION_POST_BATCH_CREATE_INVOICES}_${REQUEST_STATE.START}`:
    case `${ORGANIZATION_POST_BATCH_CREATE_INVOICES}_${REQUEST_STATE.SUCCESS}`:
    case `${ORGANIZATION_POST_BATCH_CREATE_INVOICES}_${REQUEST_STATE.FAIL}`:
      return handleRequestState({
        state, action, requestKey: ORGANIZATION_REQUESTS.orgBatchCreateInvoices,
      });
    default:
      return state;
  }
};

export default reducer;
