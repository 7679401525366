export const getStatusColor = (booking) => {
  let color = 'inherit';
  let status = null;
  if (booking?.tracking?.container?.status) {
    if (booking.tracking.container.status.vesselArrived) {
      color = 'orange';
      status = 'Vessel arrived';
    }
    if (booking.tracking.container.status.vesselDischarged) {
      color = 'green';
      status = 'Vessel discharged';
    }
    if (booking.tracking.container.status.gatedOut) {
      color = 'blue';
      status = 'Container gated out';
    }
  }
  return { status, color };
};

export const SORT_OPTIONS = {
  ARRIVAL_WEEK: 'Arrival Week',
  CARRIER: 'Carrier',
  CONTAINER_ID: 'Container Id',
  DESTINATION: 'Destination',
  ETA: 'ETA',
  INVOICE_NUMBER: 'Invoice Number',
  LOADING_WEEK: 'Loading Week',
  ORIGIN: 'Origin',
  PICKUP_DATE: 'Pickup Date',
  PRODUCT: 'Product',
  PURCHASE_ORDER: 'Purchase Order',
  SHIPPER: 'Shipper',
  VESSEL: 'Vessel',
};

export const sortKeyMap = {
  [SORT_OPTIONS.ARRIVAL_WEEK]: 'details.arrivalWeek',
  [SORT_OPTIONS.CARRIER]: 'tracking.container.carrierName',
  [SORT_OPTIONS.CONTAINER_ID]: 'tracking.container.containerId',
  [SORT_OPTIONS.DESTINATION]: 'tracking.container.status.destination.country',
  [SORT_OPTIONS.ETA]: 'tracking.container.status.eta',
  [SORT_OPTIONS.INVOICE_NUMBER]: 'booking.invoiceNumber',
  [SORT_OPTIONS.LOADING_WEEK]: 'details.loadingWeek',
  [SORT_OPTIONS.ORIGIN]: 'tracking.container.status.origin.country',
  [SORT_OPTIONS.PICKUP_DATE]: 'details.pickupDate',
  [SORT_OPTIONS.PRODUCT]: 'booking.productDescription',
  [SORT_OPTIONS.PURCHASE_ORDER]: 'booking.purchaseOrder',
  [SORT_OPTIONS.SHIPPER]: 'booking.shipper',
  [SORT_OPTIONS.VESSEL]: 'tracking.container.status.vessel',
};

export const searchableFieldsBookings = [
  'booking.purchaseOrder',
  'booking.invoiceNumber',
  'booking.shipper',
  'booking.productDescription',
  'tracking.container.containerId',
  'tracking.container.carrierName',
  'tracking.container.status.vessel',
  'tracking.container.status.origin.country',
  'tracking.container.status.origin.city',
  'tracking.container.status.destination.country',
  'tracking.container.status.destination.city',
];

export const searchableFieldsInspections = [
  '_id',
  'summary.applicantName',
  'summary.carrierName',
  'summary.carrierReference',
  'summary.carrierType',
  'summary.carrierVehicle',
  'summary.certificateId',
  'summary.commodity',
  'summary.destinationCity',
  'summary.destinationCountry',
  'summary.originCity',
  'summary.originCountry',
  'summary.purchaseOrder',
  'summary.shipperName',
  'summary.status',
  'summary.serviceTitle',
  'summary.serviceCost',
];

export const CARRIER_TYPES = {
  truck: 'truck',
  vessel: 'vessel',
  rail: 'rail',
  air: 'air',
};

export const TEMPERATURE_TYPES = {
  pulpMin: 'pulpMin',
  pulpMax: 'pulpMax',
  outside: 'outside',
  cooler: 'cooler',
  truckSetPoint: 'truckSetPoint',
  warehouse: 'warehouse',
};

export const TEMPERATURE_UNITS = ['°C', '°F'];

export const CONTACT_TYPES = {
  shipper: 'shipper',
  customer: 'customer',
  transport: 'transport',
};

export const getNotificationBannerTimeoutMs = () => 2500;
