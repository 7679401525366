import React, {
  useState, useEffect, forwardRef, useImperativeHandle,
} from 'react';

import { apiRequestState, get } from '../../../utility/utility';
import CoreFormDualAction from '../../core/form/CoreFormDualAction';
import { renderInputField, renderSelectField, validateForm } from '../../core/input/CoreInputUtils';

const fieldsToValidate = [
  'invoiceNumber',
  'invoiceDate',
  'customerName',
  'commodity',
  'purchaseOrder',
  'unitPrice',
  'taxOptionId',
  'salesItemId',
];
const InspectionFormCreateInvoice = forwardRef(({
  inspectionsCreateInvoicePostRequestState,
  dispatchInspectionsCreateInvoicePost,
}, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [inputState, setInputState] = useState({});
  const [inspectionState, setInspectionState] = useState({});
  const [organizationState, setOrganizationState] = useState({});
  const [error, setError] = useState({});

  useImperativeHandle(ref, () => ({
    open: (organization, inspection) => {
      setOrganizationState(organization);
      setInspectionState(inspection);
      setIsOpen(true);

      const taxOptions = get(organization, 'integration.quickbooks.taxOptions', []).map((taxOption) => ({ value: get(taxOption, 'TaxRateRef'), label: get(taxOption, 'Name') }));
      const salesItems = get(organization, 'integration.quickbooks.salesItems', []).map((salesItem) => ({ value: get(salesItem, 'id'), label: get(salesItem, 'name') }));
      const { taxOptionId: defaultTaxOptionId, salesItemId: defaultSalesItemId } = get(organization, 'integration.quickbooks.defaultSettings', {});

      let taxOptionValue = '';
      let salesItemValue = '';
      let salesItemLabel = '';

      // Find the index of the matching tax option
      const defaultTaxOptionIndex = taxOptions.findIndex((taxOption) => taxOption.value === defaultTaxOptionId);
      if (defaultTaxOptionIndex !== -1) {
        taxOptionValue = taxOptions[defaultTaxOptionIndex].value;
      }

      // Find the index of the matching sales item
      const defaultSalesItemIndex = salesItems.findIndex((salesItem) => salesItem.value === defaultSalesItemId);
      if (defaultSalesItemIndex !== -1) {
        salesItemValue = salesItems[defaultSalesItemIndex].value;
        salesItemLabel = salesItems[defaultSalesItemIndex].label;
      }

      setInputState({
        invoiceNumber: get(inspection, 'request.certificateId'),
        invoiceDate: get(inspection, 'onsite.assessment.startTimestamp.date'),
        customerName: get(inspection, 'participants.applicant.name'),
        commodity: get(inspection, 'shipment.logistics.commodity'),
        purchaseOrder: get(inspection, 'shipment.logistics.purchaseOrder'),
        shipperReference: get(inspection, 'shipment.logistics.shipperReference'),
        unitPrice: get(inspection, 'request.service.cost'),
        taxOptionId: taxOptionValue,
        salesItemId: salesItemValue,
        salesItemLabel,
        invoiceMemo: '',
      });
      setError({});
    },
    close: () => setIsOpen(false),
  }));

  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (inspectionsCreateInvoicePostRequestState === apiRequestState.SUCCESS) {
      closeModal();
    }
  }, [inspectionsCreateInvoicePostRequestState]);

  if (!isOpen) return null;

  const onInputChange = (event) => {
    const newState = { ...inputState };
    newState[event.target.id] = event.target.value;
    setInputState(newState);

    validateForm({
      state: newState,
      fieldsToValidate,
      setError,
    });
  };

  const handleCreateInvoice = (inspection) => {
    if (validateForm({
      state: inputState,
      fieldsToValidate,
      setError,
    })) {
      dispatchInspectionsCreateInvoicePost(inspection, { ...inputState, inspectionId: get(inspection, '_id') });
    }
  };

  const taxOptions = get(organizationState, 'integration.quickbooks.taxOptions', []).map((taxOption) => ({ value: get(taxOption, 'TaxRateRef'), label: get(taxOption, 'Name') }));
  const salesItems = get(organizationState, 'integration.quickbooks.salesItems', []).map((salesItem) => ({ value: get(salesItem, 'id'), label: get(salesItem, 'name') }));

  return (
    <CoreFormDualAction
      title="Create Invoice (Quickbooks)"
      primaryButtonCaption="Create"
      primaryButtonCallback={() => handleCreateInvoice(inspectionState, inputState)}
      primaryButtonCallbackStatus={inspectionsCreateInvoicePostRequestState === apiRequestState.LOADING}
      primaryButtonDisabled={Object.keys(error).length > 0 || inspectionsCreateInvoicePostRequestState === apiRequestState.LOADING}
      secondaryButtonCaption="Cancel"
      secondaryButtonCallback={closeModal}
    >

      {renderInputField({
        id: 'invoiceNumber', label: 'Invoice Number', inputState, onInputChange, error,
      })}
      {renderInputField({
        id: 'invoiceDate', label: 'Invoice Date', inputState, onInputChange, error, placeholder: 'Invoice Date (YYYY-MM-DD)',
      })}
      {renderInputField({
        id: 'customerName', label: 'Customer Name', inputState, onInputChange, error,
      })}
      {renderInputField({
        id: 'commodity', label: 'Commodity Description', inputState, onInputChange, error,
      })}
      {renderInputField({
        id: 'purchaseOrder', label: 'Purchase Order', inputState, onInputChange, error,
      })}
      {renderInputField({
        id: 'shipperReference', label: 'Shipper Reference', inputState, onInputChange, error,
      })}
      {renderInputField({
        id: 'unitPrice', label: 'Price', inputState, onInputChange, error,
      })}
      {renderSelectField({
        id: 'salesItemId', label: 'Sales Item', inputState, onInputChange, options: salesItems, error,
      })}
      {renderSelectField({
        id: 'taxOptionId', label: 'Taxes', inputState, onInputChange, options: taxOptions, error,
      })}
      {renderInputField({
        id: 'invoiceMemo', label: 'Invoice Customer Memo', inputState, onInputChange, error,
      })}
    </CoreFormDualAction>
  );
});

export default InspectionFormCreateInvoice;
