import {
  React, useEffect, useRef,
} from 'react';
import { connect } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import {
  organizationGet, organizationPostBatchCreateInvoices, organizationPut, subscriptionsGet, userDelete, userPost, userPut,
} from '../actions';
import {
  apiRequestState, featureCheck, get, permissionCheck,
} from '../utility/utility';
import { MEDIA_MOBILE_MAX_WIDTH } from '../utility/AppParameters';
import BackgroundVideo from '../components/BackgroudVideo';
import OrganizationFormContact from '../components/organization/forms/OrganizationFormContact';
import OrganizationFormDefect from '../components/organization/forms/OrganizationFormDefect';
import OrganizationBubbleContacts from '../components/organization/bubbles/OrganizationBubbleContacts';
import OrganizationBubbleDefects from '../components/organization/bubbles/OrganizationBubbleDefects';
import OrganizationBubbleTeammates from '../components/organization/bubbles/OrganizationBubbleTeammates';
import OrganizationBubbleOrganization from '../components/organization/bubbles/OrganizationBubbleOrganization';
import OrganizationFormOrganization from '../components/organization/forms/OrganizationFormOrganization';
import OrganizationBubbleUser from '../components/organization/bubbles/OrganizationBubbleUser';
import OrganizationFormUser from '../components/organization/forms/OrganizationFormUser';
import OrganizationFormTeammate from '../components/organization/forms/OrganizationFormTeammate';
import OrganizationBubbleSubscription from '../components/organization/bubbles/OrganizationBubbleSubscription';
import OrganizationBubbleCommodities from '../components/organization/bubbles/OrganizationBubbleCommodities';
import OrganizationBubbleMarkings from '../components/organization/bubbles/OrganizationBubbleMarkings';
import OrganizationFormCommodity from '../components/organization/forms/OrganizationFormCommodity';
import OrganizationFormMarking from '../components/organization/forms/OrganizationFormMarking';
import OrganizationBubbleIntegration from '../components/organization/bubbles/OrganizationBubbleIntegration';
import OrganizationFormBatchCreateInvoice from '../components/organization/forms/OrganizationFormBatchCreateInvoice';

function OrganizationPage({
  me,
  dispatchOrganizationsGet,
  dispatchOrganizationsPut,
  dispatchInspectionsCreateBatchInvoicePost,
  organizationBatchCreateInvoicesIsLoading,
  dispatchUserPost,
  dispatchUserPut,
  dispatchUserDelete,
  organization,
  organizationPutIsLoading,
  organizationIsLoading,
  userPutRequestState,
  userPostRequestState,
  userDeleteRequestState,
  subscription,
  subscriptionsGetRequestState,
  dispatchSubscriptionsGet,
}) {
  const isDesktopOrLaptop = useMediaQuery({ minDeviceWidth: MEDIA_MOBILE_MAX_WIDTH });

  const organizationFormOrganizationRef = useRef();
  const organizationFormUserRef = useRef();
  const organizationFormTeammateRef = useRef();
  const organizationFormContactRef = useRef();
  const organizationFormDefectRef = useRef();
  const organizationFormCommodityRef = useRef();
  const organizationFormMarkingRef = useRef();
  const organizationBatchCreateInvoiceModalRef = useRef();

  useEffect(() => {
    dispatchOrganizationsGet();
    dispatchSubscriptionsGet();
  }, []);

  useEffect(() => {
    if (!organizationIsLoading && (userPutRequestState === apiRequestState.SUCCESS
      || userPostRequestState === apiRequestState.SUCCESS
      || userDeleteRequestState === apiRequestState.SUCCESS)) {
      dispatchOrganizationsGet();
    }
  }, [userPutRequestState, userPostRequestState, userDeleteRequestState, organizationIsLoading]);

  const handleButtonOrganizationFormOrganizationOpen = (org) => {
    organizationFormOrganizationRef.current.open(org);
  };
  const handleButtonOrganizationFormUserOpen = (user) => {
    organizationFormUserRef.current.open(user);
  };
  const handleButtonOrganizationFormTeammateOpen = (org, user) => {
    organizationFormTeammateRef.current.open(org, user);
  };
  const handleButtonOrganizationFormContactOpen = (org, contact) => {
    organizationFormContactRef.current.open(org, contact);
  };
  const handleButtonOrganizationFormDefectOpen = (org, defect) => {
    organizationFormDefectRef.current.open(org, defect);
  };
  const handleButtonOrganizationFormCommodityOpen = (org, commodity) => {
    organizationFormCommodityRef.current.open(org, commodity);
  };
  const handleButtonOrganizationFormMarkingOpen = (org, marking) => {
    organizationFormMarkingRef.current.open(org, marking);
  };
  const handleButtonOrganizationBatchCreateInvoiceModalOpen = (org, inspection) => {
    organizationBatchCreateInvoiceModalRef.current.open(org, inspection);
  };
  const renderModals = () => (
    <>
      <OrganizationFormOrganization
        ref={organizationFormOrganizationRef}
        organizationPutIsLoading={organizationPutIsLoading}
        dispatchOrganizationsPut={dispatchOrganizationsPut}
      />
      <OrganizationFormUser
        ref={organizationFormUserRef}
        userPutRequestState={userPutRequestState}
        dispatchUserPut={dispatchUserPut}
      />
      <OrganizationFormTeammate
        ref={organizationFormTeammateRef}
        userPostRequestState={userPostRequestState}
        dispatchUserPost={dispatchUserPost}
        userPutRequestState={userPutRequestState}
        dispatchUserPut={dispatchUserPut}
      />
      <OrganizationFormContact
        ref={organizationFormContactRef}
        organizationPutIsLoading={organizationPutIsLoading}
        dispatchOrganizationsPut={dispatchOrganizationsPut}
      />
      <OrganizationFormDefect
        ref={organizationFormDefectRef}
        organizationPutIsLoading={organizationPutIsLoading}
        dispatchOrganizationsPut={dispatchOrganizationsPut}
      />
      <OrganizationFormCommodity
        ref={organizationFormCommodityRef}
        organizationPutIsLoading={organizationPutIsLoading}
        dispatchOrganizationsPut={dispatchOrganizationsPut}
      />
      <OrganizationFormMarking
        ref={organizationFormMarkingRef}
        organizationPutIsLoading={organizationPutIsLoading}
        dispatchOrganizationsPut={dispatchOrganizationsPut}
      />
      <OrganizationFormBatchCreateInvoice
        ref={organizationBatchCreateInvoiceModalRef}
        organizationBatchCreateInvoicesIsLoading={organizationBatchCreateInvoicesIsLoading}
        dispatchInspectionsCreateBatchInvoicePost={dispatchInspectionsCreateBatchInvoicePost}
      />
    </>
  );

  return (
    <div style={{ paddingBottom: '200px' }}>
      {renderModals()}
      <OrganizationBubbleUser
        user={me}
        handleButtonOrganizationFormUserOpen={handleButtonOrganizationFormUserOpen}
      />
      <OrganizationBubbleOrganization
        organization={organization}
        handleButtonOrganizationFormOrganizationOpen={handleButtonOrganizationFormOrganizationOpen}
      />
      {permissionCheck(me, ['permissions.subscription.view'])
        && (
          <OrganizationBubbleSubscription
            organization={organization}
            subscription={subscription}
            subscriptionsGetRequestState={subscriptionsGetRequestState}
          />
        )}
      {permissionCheck(me, ['permissions.integrations.view'])
        && (
          <OrganizationBubbleIntegration
            organization={organization}
            handleButtonOrganizationBatchCreateInvoiceModalOpen={handleButtonOrganizationBatchCreateInvoiceModalOpen}
          />
        )}
      {permissionCheck(me, ['permissions.users.view'])
        && (
          <OrganizationBubbleTeammates
            organization={organization}
            handleButtonOrganizationFormTeammateOpen={handleButtonOrganizationFormTeammateOpen}
            dispatchUserDelete={dispatchUserDelete}
          />
        )}
      <OrganizationBubbleContacts
        organization={organization}
        handleButtonOrganizationFormContactOpen={handleButtonOrganizationFormContactOpen}
        dispatchOrganizationsPut={dispatchOrganizationsPut}
      />
      {
        featureCheck(organization, ['inspectionSettings'])
        && permissionCheck(me, ['permissions.inspections.view'])
        && (
          <>
            <OrganizationBubbleDefects
              organization={organization}
              handleButtonOrganizationFormDefectOpen={handleButtonOrganizationFormDefectOpen}
              dispatchOrganizationsPut={dispatchOrganizationsPut}
            />
            <OrganizationBubbleCommodities
              organization={organization}
              handleButtonOrganizationFormCommodityOpen={handleButtonOrganizationFormCommodityOpen}
              dispatchOrganizationsPut={dispatchOrganizationsPut}
            />
            <OrganizationBubbleMarkings
              organization={organization}
              handleButtonOrganizationFormDefectOpen={handleButtonOrganizationFormMarkingOpen}
              dispatchOrganizationsPut={dispatchOrganizationsPut}
            />
          </>
        )
      }
      {isDesktopOrLaptop && <BackgroundVideo videoSrc="wallpaper.mov" style={{ zIndex: '-1' }} />}
    </div>
  );
}

const mapStateToProps = (state) => ({
  me: state.users.me,
  organization: state.organizations.organization,
  organizationIsLoading: get(state, 'organizations.orgGet.isLoading', false),
  organizationBatchCreateInvoicesIsLoading: get(state, 'organizations.orgBatchCreateInvoices.isLoading', false),
  organizationPutIsLoading: get(state, 'organizations.orgPut.isLoading', false),
  userPutRequestState: state.users.userPutRequestState,
  userPostRequestState: state.users.userPostRequestState,
  userDeleteRequestState: state.users.userDeleteRequestState,
  subscription: state.subscriptions.subscription,
  subscriptionsGetRequestState: state.subscriptions.subscriptionsGetRequestState,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchOrganizationsGet: () => dispatch(organizationGet()),
  dispatchOrganizationsPut: (body) => dispatch(organizationPut(body)),
  dispatchUserPost: (user) => dispatch(userPost(user)),
  dispatchUserPut: (user) => dispatch(userPut(user)),
  dispatchUserDelete: (user) => dispatch(userDelete(user)),
  dispatchSubscriptionsGet: () => dispatch(subscriptionsGet()),
  dispatchInspectionsCreateBatchInvoicePost: () => dispatch(organizationPostBatchCreateInvoices()),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationPage);
