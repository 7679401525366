import React, {
  useState, useEffect, forwardRef, useImperativeHandle,
} from 'react';
import _ from 'lodash';
import { get, set } from '../../../utility/utility';
import CoreFormDualAction from '../../core/form/CoreFormDualAction';
import InputField from '../../core/input/InputField';
import Select from '../../Select';
import { CONTACT_TYPES } from '../../../utility/vesselParams';

const OrganizationFormContact = forwardRef(({
  dispatchOrganizationsPut,
  organizationPutIsLoading,
}, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentContacts, setCurrentContacts] = useState([]);
  const [inputState, setInputState] = useState({});
  const [error, setError] = useState({});
  useImperativeHandle(ref, () => ({
    open: (organization, contact) => {
      const { contacts } = _.pick(organization, ['contacts']);
      setCurrentContacts(_.cloneDeep(contacts));
      if (contact) {
        setInputState(_.cloneDeep(contact));
      } else {
        setInputState({});
      }
      setIsOpen(true);
      setError({});
    },
    close: () => setIsOpen(false),
  }));

  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (!organizationPutIsLoading) {
      closeModal();
    }
  }, [organizationPutIsLoading]);

  if (!isOpen) return null;

  const validateForm = (state) => {
    // Define the fields to validate
    const fieldsToValidate = ['company.name', 'type'];

    // Initialize an object to hold error messages for each field
    const errors = {};

    // Iterate over the fields and validate
    fieldsToValidate.forEach((fieldId) => {
      if (!get(state, fieldId)) {
        errors[fieldId] = `${_.startCase(fieldId)} is required`;
      }
    });

    // Set the error state based on the validation results
    setError(errors);
    return Object.keys(errors).length === 0;
  };

  const handleInputChange = (event, path) => {
    const newState = { ...inputState };
    set(newState, path, event.target.value);
    setInputState(newState);
    validateForm(newState);
  };

  const handleSave = () => {
    if (validateForm(inputState)) {
      const contactIndex = currentContacts.findIndex(
        (element) => get(element, '_id').toString() === inputState._id,
      );

      if (contactIndex !== -1) {
        // Update existing contact
        dispatchOrganizationsPut({
          contactsUpdates: { contactsToUpdate: [inputState] },
        });
      } else {
        // Add new contact
        dispatchOrganizationsPut({
          contactsUpdates: { contactsToAdd: [inputState] },
        });
      }
    }
  };

  const renderInputField = (id, state, path = id) => (
    <InputField
      id={id}
      label={_.startCase(id)}
      placeholder={_.startCase(id)}
      value={get(state, path)}
      onChange={(event) => handleInputChange(event, path)}
    />
  );

  const renderSelectField = (id, state, options, path = id) => (
    <Select
      id={id}
      value={get(state, path)}
      onChange={(event) => handleInputChange(event, path)}
      options={[''].concat(options)}
      placeholder={_.startCase(id)}
    >
      {_.startCase(id)}
    </Select>
  );

  return (
    <CoreFormDualAction
      title="Contact"
      primaryButtonCaption="Save"
      primaryButtonCallback={() => handleSave()}
      primaryButtonCallbackStatus={organizationPutIsLoading}
      primaryButtonDisabled={Object.keys(error).length > 0 || organizationPutIsLoading}
      secondaryButtonCaption="Cancel"
      secondaryButtonCallback={closeModal}
    >
      <div>
        {renderInputField('name', inputState, 'company.name')}
        {get(error, 'company.name', false) && <div style={{ color: 'var(--color-warning)' }}>{error['company.name']}</div>}
        {renderSelectField('type', inputState, Object.keys(CONTACT_TYPES).map(((type) => ({ label: _.startCase(type), value: type }))), 'type')}
        {get(error, 'type', false) && <div style={{ color: 'var(--color-warning)' }}>{error.type}</div>}
        {renderInputField('line1', inputState, 'company.address.line1')}
        {renderInputField('line2', inputState, 'company.address.line2')}
        {renderInputField('city', inputState, 'company.address.city')}
        {renderInputField('state', inputState, 'company.address.state')}
        {renderInputField('country', inputState, 'company.address.country')}
        {renderInputField('postalCode', inputState, 'company.address.postalCode')}
      </div>
    </CoreFormDualAction>
  );
});

export default OrganizationFormContact;
