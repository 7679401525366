import React, { useState, useRef } from 'react';
import './PackingListTable.css'; // Ensure this file is imported
import { cloneDeep } from 'lodash';
import {
  INSPECTION_INSPECTED_GRADES, INSPECTION_PACKAGING_TYPES, INSPECTION_SIZING_METHODS, INSPECTION_WEIGHT_UNITS,
} from '../utility/InspectionsUtils';

function PackingListTable({ handleOnChange, initialPackingList = [] }) {
  const [items, setItems] = useState(() => {
    const nonEmptyRows = initialPackingList.length ? initialPackingList : [];
    return [
      ...nonEmptyRows.map((item) => ({ ...item, id: crypto.randomUUID() })), // Assign unique IDs
      {
        id: crypto.randomUUID(),
        description: '',
        marking: '',
        grade: '',
        quantity: '',
        packingType: '',
        sizingMethod: '',
        size: '',
        weight: '',
        weightUnit: '',
      },
    ];
  });

  const [editing, setEditing] = useState({ rowIndex: null, field: null });
  const inputRefs = useRef({});
  const fields = ['description', 'marking', 'grade', 'quantity', 'packingType', 'sizingMethod', 'size', 'weight', 'weightUnit'];

  const handleEdit = (rowIndex, field) => {
    setEditing({ rowIndex, field });
    setTimeout(() => {
      const refKey = `${rowIndex}-${field}`;
      if (inputRefs.current[refKey]) {
        inputRefs.current[refKey].focus();
      }
    }, 0);
  };

  const handleChange = (rowIndex, field, value) => {
    setItems((prevItems) => {
      const updatedItems = [...prevItems];
      updatedItems[rowIndex] = { ...updatedItems[rowIndex], [field]: value };
      handleOnChange(cloneDeep(updatedItems));
      return updatedItems;
    });
  };

  const handleBlur = () => {
    setTimeout(() => {
      if (!document.activeElement || !document.activeElement.closest('.packing-list-table')) {
        setEditing({ rowIndex: null, field: null });

        // Remove extra blank rows while keeping one empty row at the end
        setItems((prevItems) => {
          const nonEmptyRows = prevItems.filter((item) => fields.some((field) => (item[field] ? String(item[field]).trim() !== '' : false)));

          return [
            ...nonEmptyRows.map((item) => ({ ...item, id: item.id || crypto.randomUUID() })),
            {
              id: crypto.randomUUID(),
              description: '',
              marking: '',
              grade: '',
              quantity: '',
              packingType: '',
              sizingMethod: '',
              size: '',
              weight: '',
              weightUnit: '',
            },
          ];
        });
      }
    }, 100);
  };

  const moveToNextField = (rowIndex, field) => {
    const fieldIndex = fields.indexOf(field);
    let nextRowIndex = rowIndex;
    let nextField = fields[fieldIndex + 1];

    if (!nextField) {
      nextRowIndex = rowIndex + 1;
      nextField = 'description';

      if (nextRowIndex >= items.length) {
        setItems((prevItems) => [
          ...prevItems,
          {
            id: crypto.randomUUID(),
            description: '',
            marking: '',
            grade: '',
            quantity: '',
            packingType: '',
            sizingMethod: '',
            size: '',
            weight: '',
            weightUnit: '',
          },
        ]);
      }
    }

    setEditing({ rowIndex: nextRowIndex, field: nextField });

    setTimeout(() => {
      const nextInput = inputRefs.current[`${nextRowIndex}-${nextField}`];
      if (nextInput) {
        nextInput.focus();
      }
    }, 50);
  };

  const handleKeyDown = (e, rowIndex, field) => {
    if (e.key === 'Enter' || e.key === 'Tab') {
      e.preventDefault();
      moveToNextField(rowIndex, field);
    }
  };

  return (
    <div className="packing-list-container">
      <table className="packing-list-table">
        <thead>
          <tr>
            <th>Commodity</th>
            <th>Marking</th>
            <th>Grade</th>
            <th>Quantity</th>
            <th>Packing Type</th>
            <th>Sizing Method</th>
            <th>Size</th>
            <th>Weight</th>
            <th>Weight Unit</th>
          </tr>
        </thead>
        <tbody>
          {items.map((item, rowIndex) => (
            <tr key={item.id} className={rowIndex === items.length - 1 ? 'new-row' : ''}>
              {fields.map((field, fieldIndex) => {
                const refKey = `${rowIndex}-${field}`;
                const refCallback = (el) => {
                  inputRefs.current[refKey] = el;
                };

                if (editing.rowIndex === rowIndex && editing.field === field) {
                  if (['grade', 'packingType', 'sizingMethod', 'weightUnit'].includes(field)) {
                    let options = [];
                    if (field === 'grade') {
                      options = Object.values(INSPECTION_INSPECTED_GRADES);
                    } else if (field === 'packingType') {
                      options = Object.values(INSPECTION_PACKAGING_TYPES);
                    } else if (field === 'sizingMethod') {
                      options = Object.values(INSPECTION_SIZING_METHODS);
                    } else {
                      options = Object.values(INSPECTION_WEIGHT_UNITS);
                    }

                    return (
                      <td key={field}>
                        <select
                          ref={refCallback}
                          value={item[field] || ''}
                          onChange={(e) => handleChange(rowIndex, field, e.target.value)}
                          onBlur={handleBlur}
                          onKeyDown={(e) => handleKeyDown(e, rowIndex, field)}
                        >
                          <option value="">Select</option>
                          {options.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      </td>
                    );
                  }
                  return (
                    <td key={field}>
                      <input
                        ref={refCallback}
                        type="text"
                        value={String(item[field])}
                        onChange={(e) => handleChange(rowIndex, field, e.target.value)}
                        onBlur={handleBlur}
                        onKeyDown={(e) => handleKeyDown(e, rowIndex, field)}
                      />
                    </td>
                  );
                }

                return (
                  <td key={field} onClick={() => handleEdit(rowIndex, field)}>
                    {fieldIndex === 0 && rowIndex === items.length - 1 && !item[field] ? (
                      <span className="placeholder">New item...</span>
                    ) : (
                      item[field]
                    )}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default PackingListTable;
