import { get, newCreateRequestAction } from '../utility/utility';
import { notificationsShow } from './notifications';
import { createRequestAction } from '../utility/reduxUtils';

const ENDPOINT_PATH = 'organizations';
export const ORGANIZATION_POST_BATCH_CREATE_INVOICES = 'ORGANIZATION_POST_BATCH_CREATE_INVOICES';
export const ORGANIZATION_PUT = 'ORGANIZATION_PUT';

export const ORGANIZATION_GET_REQUEST_START = 'ORGANIZATION_GET_REQUEST_START';
export const ORGANIZATION_GET_REQUEST_SUCCESS = 'ORGANIZATION_GET_REQUEST_SUCCESS';
export const ORGANIZATION_GET_REQUEST_FAIL = 'ORGANIZATION_GET_REQUEST_FAIL';
export const ORGANIZATION_GET_INSPECTORS_REQUEST_START = 'ORGANIZATION_GET_INSPECTORS_REQUEST_START';
export const ORGANIZATION_GET_INSPECTORS_REQUEST_SUCCESS = 'ORGANIZATION_GET_INSPECTORS_REQUEST_SUCCESS';
export const ORGANIZATION_GET_INSPECTORS_REQUEST_FAIL = 'ORGANIZATION_GET_INSPECTORS_REQUEST_FAIL';

export const organizationGet = () => async (dispatch, _getState, { apiClient, log }) => {
  try {
    dispatch(newCreateRequestAction({ type: ORGANIZATION_GET_REQUEST_START, isLoading: true }));
    const { data } = await apiClient.httpGet(`${ENDPOINT_PATH}/`);
    dispatch(newCreateRequestAction({ type: ORGANIZATION_GET_REQUEST_SUCCESS, data }));
  } catch (err) {
    log(err);
    const message = 'Failed to retrieve organization profile';
    dispatch(newCreateRequestAction({ type: ORGANIZATION_GET_REQUEST_FAIL, error: message }));
  }
};

export const organizationGetInspectors = () => async (dispatch, _getState, { apiClient, log }) => {
  try {
    dispatch(newCreateRequestAction({ type: ORGANIZATION_GET_INSPECTORS_REQUEST_START, isLoading: true }));
    const { data } = await apiClient.httpGet(`${ENDPOINT_PATH}/inspectors`);
    dispatch(newCreateRequestAction({ type: ORGANIZATION_GET_INSPECTORS_REQUEST_SUCCESS, data }));
  } catch (err) {
    log(err);
    const message = 'Failed to retrieve inspector organizations';
    dispatch(newCreateRequestAction({ type: ORGANIZATION_GET_INSPECTORS_REQUEST_FAIL, error: message }));
  }
};

export const organizationPut = (body) => createRequestAction({
  requestType: ORGANIZATION_PUT,
  endpoint: 'organizations',
  method: 'httpPut',
  payload: body,
  successMessage: 'Successfully updated organization',
  failureMessage: 'Failed to update organization',
});

export const organizationPostBatchCreateInvoices = () => createRequestAction({
  requestType: ORGANIZATION_POST_BATCH_CREATE_INVOICES,
  endpoint: 'inspections/sync/invoices',
  method: 'httpPost',
  onSuccess: (data, _headers, dispatch) => dispatch(notificationsShow('success', get(data, 'message'))),
  failureMessage: 'Failed to batch create invoices',
});
