// TabView.js
import React, { useState } from 'react';
import './TabView.css';
import { get } from '../utility/utility';

function TabView({ tabs }) {
  const [activeTab, setActiveTab] = useState(get(tabs[0], 'name'));

  return (
    <div className="tab-view-container">
      <div className="tabs-header">
        {tabs.map((tab) => (
          <button
            type="button"
            key={get(tab, 'name')}
            className={`tabs-header-button ${activeTab === get(tab, 'name') ? 'active' : ''}`}
            onClick={() => setActiveTab(get(tab, 'name'))}
          >
            {get(tab, 'name')}
          </button>
        ))}
      </div>
      <div className="tabs-content">
        {tabs.find((tab) => get(tab, 'name') === activeTab).content}
      </div>
    </div>
  );
}

export default TabView;
