import React, {
  useState, useEffect, forwardRef, useImperativeHandle,
} from 'react';
import { get } from '../../../utility/utility';
import CoreFormDualAction from '../../core/form/CoreFormDualAction';
import { INSPECTION_STATES } from '../../../utility/InspectionsUtils';
import { renderTextArea } from '../../core/input/CoreInputUtils';

const InspectionFormFlag = forwardRef(({ dispatchInspectionsMark, inspectionsPutMarkIsLoading }, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentInspections, setCurrentInspections] = useState([]);
  const [inputState, setInputState] = useState({});

  const [error, setError] = useState(null);

  useImperativeHandle(ref, () => ({
    open: (inspections) => {
      setCurrentInspections(inspections);
      setInputState(get(inspections[0], 'summary', {}));
      setIsOpen(true);
      setError(null);
    },
    close: () => setIsOpen(false),
  }));

  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (!inspectionsPutMarkIsLoading) {
      closeModal();
    }
  }, [inspectionsPutMarkIsLoading]);

  const handlePrimaryAction = () => {
    const requestBody = { status: INSPECTION_STATES.requiresAttention, inspectionIds: currentInspections.map((inspection) => inspection._id), assessment: inputState };
    dispatchInspectionsMark(requestBody);
  };

  const onInputChange = (event, path) => {
    const newState = { ...inputState };
    newState[path] = event.target.value;
    setInputState(newState);
  };

  const renderInspectionSummary = (inspection) => {
    const output = [];
    const applicantName = get(inspection, 'summary.applicantName', false);
    const purchaseOrder = get(inspection, 'summary.purchaseOrder', false);
    if (applicantName) {
      output.push(applicantName);
    }
    if (purchaseOrder) {
      output.push(`#${purchaseOrder}`);
    }
    return output.join(' ');
  };

  if (!isOpen) return null;

  return (
    <CoreFormDualAction
      title="Flag Inspection"
      primaryButtonCaption="Flag"
      primaryButtonCallback={handlePrimaryAction}
      primaryButtonCallbackStatus={inspectionsPutMarkIsLoading}
      primaryButtonDisabled={error || inspectionsPutMarkIsLoading}
      secondaryButtonCaption="Cancel"
      secondaryButtonCallback={closeModal}
    >
      <p>Selected inspections: </p>
      {currentInspections.map((inspection) => (
        <div key={get(inspection, '_id')}>
          <strong>
            <p style={{ paddingLeft: '10px', paddingTop: '10px', margin: '0px' }}>
              {get(inspection, 'summary.commodity')}
            </p>
          </strong>
          <p style={{ paddingLeft: '10px', paddingTop: '0px', margin: '0px' }}>
            {renderInspectionSummary(inspection)}
          </p>
          <p style={{ paddingLeft: '10px', paddingTop: '0px', margin: '0px' }}>
            {`${get(inspection, 'summary.certificateId')}`}
          </p>
        </div>
      ))}
      {renderTextArea({ id: 'internalMemo', inputState, onInputChange })}
    </CoreFormDualAction>
  );
});

export default InspectionFormFlag;
